.hero {
  position: relative;
  height: 22rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
  overflow: hidden;
}

.hero .container {
  height: inherit;
  overflow: hidden;
}

.hero .image-container {
  max-height: inherit;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.hero h1 {
  color: #0d47a1;
  font-size: 1.7rem;
  padding: 0 1em;
  z-index: 2;
  text-align: left;
}

/* @media screen and (min-width: 560px) {
  .hero h1 {
    font-size: 2rem;
  }
} */

@media screen and (min-width: 768px) {
  .hero .container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: 22em;
  }

  .hero .image-container {
    position: initial;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1024px) {
  /* .hero {
    height: 30rem;
  } */

  /* .hero .container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
  }

  .hero .image-container {
    width: 100%;
    height: 100%;
    position: static;
    object-fit: cover;
  }

  .hero img {
    width: 100%;
    height: 100%;
  } */
}
