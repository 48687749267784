.burger-button {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 5;
  line-height: 50px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 50%;
  font-size: 1.5em;
}

.burger-button.dark {
  background: #0d47a1;
}

.header {
  position: sticky;
  width: 100%;
  height: 75px;
  top: 0;
  left: 0;
  z-index: 5;

  font-size: 1.2em;
  grid-area: header;
  display: flex;
  align-items: center;
  background-color: #0d47a1;
}

.header figure {
  display: flex;
  align-items: center;
}

.header figure img {
  height: 2rem;
}

.header .menu {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(5, 111, 255, 0.9);
  z-index: 3;
  top: 0;
  left: -100vw;
  bottom: 0;
  width: 100vw;
  transition: 0.3s;
}

.header .is-active {
  left: 0;
}

.header .menu ul {
  font-size: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.header .menu ul li {
  height: inherit;
}

.header .menu ul li a {
  display: flex;
  justify-content: center;
  height: inherit;
  color: white;
  text-decoration: none;
  align-items: center;
  padding: 15px 0;
}

.header .active {
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .burger-button {
    display: none;
  }

  .header .container {
    flex-direction: row;
    justify-content: space-between;
  }

  .header figure {
    margin: 0;
  }

  .header figure img {
    height: 2rem;
  }

  .header .menu {
    width: auto;
    height: 100%;
    position: initial;
    justify-content: end;
    background: none;
    font-size: 1rem;
  }

  .header .menu ul {
    height: inherit;
    font-size: 1.2em;
    flex-direction: row;
  }

  .header .menu ul li a {
    padding: 0 1em;
    height: inherit;
  }
}
