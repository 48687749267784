.footer {
  height: 12em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-area: footer;
  color: white;
  background-color: #0d47a1;
  text-align: center;
}

.footer .container {
  display: flex;
  flex-direction: column;
}

.footer figure {
  height: 30px;
  margin-bottom: 10px;
}

.footer figure img {
  height: 100%;
}

.icon-github svg {
  fill: white;
  width: 100%;
}
