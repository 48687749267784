.about {
  grid-area: about;
}

.habilities {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto auto auto;
  grid-gap: 1em;
  margin-top: 2em;
}

.hability > span {
  display: block;
  border-radius: 5px;
  padding: 0.5em 1em;
  font-weight: 600;
  background: rgba(5, 111, 255, 0.1);
}

.sub-habilities {
  padding: 20px 5px 10px 0;
}

.sub-habilities li {
  display: flex;
  align-items: center;
  padding: 0.5em;
}

.sub-habilities span {
  padding-left: 1em;
}

.sub-habilities img {
  height: 30px;
  width: 50px;
}

@media screen and (min-width: 1024px) {
  .habilities {
    grid-template-columns: auto auto auto;
    grid-auto-rows: 1fr;
    grid-gap: 1em;
  }
}
