.app {
  width: inherit;
  display: grid;
  grid-template-areas:
    "header"
    "hero"
    "content"
    "footer";
  grid-template-rows: 75px auto 1fr auto;
}

h1 {
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  margin: 0;
  text-align: center;
}

.container {
  max-width: 1200px;
  flex: 1;
  margin: 0 auto;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  height: inherit;
  padding: 0 1em;
}

.main-container {
  margin: 2rem auto;
  width: 100%;
  display: grid;
  grid-template-areas:
    "about"
    "portfolio"
    "contact";
  grid-area: content;
}

.main-container section {
  border-bottom: 3px solid rgba(5, 111, 255, 0.3);
  padding-bottom: 1em;
}

.main-container > section:before {
  height: 85px;
  display: block;
  content: "";
}

.main-container section h2 {
  color: #0d47a1;
  text-decoration: underline;
  margin-top: 0;
}

p {
  line-height: 1.7em;
}

a:hover {
  cursor: pointer;
}

/* Media Queries */

@media screen and (min-width: 768px) {
  .app {
    width: 100vw;
    grid-template-rows: 75px auto 1fr auto;
  }

  .container {
    padding: 0em 3em;
  }
}

/* icomoon */

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?t1159t");
  src: url("./assets/fonts/icomoon.eot?t1159t#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?t1159t") format("truetype"),
    url("./assets/fonts/icomoon.woff?t1159t") format("woff"),
    url("./assets/fonts/icomoon.svg?t1159t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e9bd";
}
.icon-cross:before {
  content: "\ea0f";
}

/* icomoon end */
