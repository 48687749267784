html {
  box-sizing: border-box;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

.app {
  width: inherit;
  display: grid;
  grid-template-areas:
    "header"
    "hero"
    "content"
    "footer";
  grid-template-rows: 75px auto 1fr auto;
}

h1 {
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  margin: 0;
  text-align: center;
}

.container {
  max-width: 1200px;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0 auto;
  display: inherit;
  -webkit-justify-content: inherit;
          justify-content: inherit;
  -webkit-align-items: inherit;
          align-items: inherit;
  height: inherit;
  padding: 0 1em;
}

.main-container {
  margin: 2rem auto;
  width: 100%;
  display: grid;
  grid-template-areas:
    "about"
    "portfolio"
    "contact";
  grid-area: content;
}

.main-container section {
  border-bottom: 3px solid rgba(5, 111, 255, 0.3);
  padding-bottom: 1em;
}

.main-container > section:before {
  height: 85px;
  display: block;
  content: "";
}

.main-container section h2 {
  color: #0d47a1;
  text-decoration: underline;
  margin-top: 0;
}

p {
  line-height: 1.7em;
}

a:hover {
  cursor: pointer;
}

/* Media Queries */

@media screen and (min-width: 768px) {
  .app {
    width: 100vw;
    grid-template-rows: 75px auto 1fr auto;
  }

  .container {
    padding: 0em 3em;
  }
}

/* icomoon */

@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.97fabe56.eot);
  src: url(/static/media/icomoon.97fabe56.eot#iefix)
      format("embedded-opentype"),
    url(/static/media/icomoon.fc97ebb3.ttf) format("truetype"),
    url(/static/media/icomoon.3674e3b2.woff) format("woff"),
    url(/static/media/icomoon.b60c103d.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\E9BD";
}
.icon-cross:before {
  content: "\EA0F";
}

/* icomoon end */

.burger-button {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 5;
  line-height: 50px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 50%;
  font-size: 1.5em;
}

.burger-button.dark {
  background: #0d47a1;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: 75px;
  top: 0;
  left: 0;
  z-index: 5;

  font-size: 1.2em;
  grid-area: header;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #0d47a1;
}

.header figure {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header figure img {
  height: 2rem;
}

.header .menu {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: rgba(5, 111, 255, 0.9);
  z-index: 3;
  top: 0;
  left: -100vw;
  bottom: 0;
  width: 100vw;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.header .is-active {
  left: 0;
}

.header .menu ul {
  font-size: 2rem;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.header .menu ul li {
  height: inherit;
}

.header .menu ul li a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: inherit;
  color: white;
  text-decoration: none;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px 0;
}

.header .active {
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .burger-button {
    display: none;
  }

  .header .container {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .header figure {
    margin: 0;
  }

  .header figure img {
    height: 2rem;
  }

  .header .menu {
    width: auto;
    height: 100%;
    position: static;
    position: initial;
    -webkit-justify-content: end;
            justify-content: end;
    background: none;
    font-size: 1rem;
  }

  .header .menu ul {
    height: inherit;
    font-size: 1.2em;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .header .menu ul li a {
    padding: 0 1em;
    height: inherit;
  }
}

.hero {
  position: relative;
  height: 22rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  color: #fff;
  overflow: hidden;
}

.hero .container {
  height: inherit;
  overflow: hidden;
}

.hero .image-container {
  max-height: inherit;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.hero h1 {
  color: #0d47a1;
  font-size: 1.7rem;
  padding: 0 1em;
  z-index: 2;
  text-align: left;
}

/* @media screen and (min-width: 560px) {
  .hero h1 {
    font-size: 2rem;
  }
} */

@media screen and (min-width: 768px) {
  .hero .container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: 22em;
  }

  .hero .image-container {
    position: static;
    position: initial;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1024px) {
  /* .hero {
    height: 30rem;
  } */

  /* .hero .container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
  }

  .hero .image-container {
    width: 100%;
    height: 100%;
    position: static;
    object-fit: cover;
  }

  .hero img {
    width: 100%;
    height: 100%;
  } */
}

.about {
  grid-area: about;
}

.habilities {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto auto auto;
  grid-gap: 1em;
  margin-top: 2em;
}

.hability > span {
  display: block;
  border-radius: 5px;
  padding: 0.5em 1em;
  font-weight: 600;
  background: rgba(5, 111, 255, 0.1);
}

.sub-habilities {
  padding: 20px 5px 10px 0;
}

.sub-habilities li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5em;
}

.sub-habilities span {
  padding-left: 1em;
}

.sub-habilities img {
  height: 30px;
  width: 50px;
}

@media screen and (min-width: 1024px) {
  .habilities {
    grid-template-columns: auto auto auto;
    grid-auto-rows: 1fr;
    grid-gap: 1em;
  }
}

.portfolio {
  grid-area: portfolio;
}

.portfolio-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.portfolio-item {
  position: relative;
  border: 2px solid #ccc;
  overflow: hidden;
}

.portfolio img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.portfolio-item-detail {
  padding: 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(13, 71, 161, 0.9);
  /* background-color: #0d47a1; */
  color: white;
  font-weight: bold;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2em;
  text-align: center;
}

.portfolio-item-detail p {
  font-weight: normal;
  font-size: 0.9em;
}

.portfolio-item:hover .portfolio-item-detail {
  display: -webkit-flex;
  display: flex;
}

.portfolio-item-detail span {
  margin-bottom: 20px;
  display: block;
}

.portfolio-detail-button {
  text-decoration: none;
  color: white;
  background-color: #555;
  border-radius: 5px;
  padding: 0.5em;
}

h2 {
  padding-top: 0;
}

@media screen and (min-width: 440px) {
}

@media screen and (min-width: 768px) {
  .portfolio-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .portfolio-list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
  }
}

.contact {
  grid-area: contact;
  margin-bottom: 100px;
}

.contact .contact-form {
  margin-top: 50px;
}

.contact .contact-form form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact .contact-form label {
  padding-bottom: 10px;
  font-weight: 600;
  color: #0d47a1;
}

.contact .contact-form input,
.contact .contact-form textarea {
  font-family: "Raleway", sans-serif;
  border: 1px solid #0d47a1;
  margin-bottom: 10px;
  font-size: 1em;
  padding: 10px;
  outline: none;
}

.contact .contact-form button {
  font-family: "Raleway", sans-serif;
  margin-top: 10px;
  border: none;
  width: 200px;
  display: block;
  background-color: #0d47a1;
  color: white;
  font-size: 1em;
  font-weight: 600;
  padding: 10px;
}

.contact a {
  text-decoration: none;
  color: #0d47a1;
  cursor: pointer;
}

.footer {
  height: 12em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  grid-area: footer;
  color: white;
  background-color: #0d47a1;
  text-align: center;
}

.footer .container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer figure {
  height: 30px;
  margin-bottom: 10px;
}

.footer figure img {
  height: 100%;
}

.icon-github svg {
  fill: white;
  width: 100%;
}

