html {
  box-sizing: border-box;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}
