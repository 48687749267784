.portfolio {
  grid-area: portfolio;
}

.portfolio-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.portfolio-item {
  position: relative;
  border: 2px solid #ccc;
  overflow: hidden;
}

.portfolio img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.portfolio-item-detail {
  padding: 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  background-color: rgba(13, 71, 161, 0.9);
  /* background-color: #0d47a1; */
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  text-align: center;
}

.portfolio-item-detail p {
  font-weight: normal;
  font-size: 0.9em;
}

.portfolio-item:hover .portfolio-item-detail {
  display: flex;
}

.portfolio-item-detail span {
  margin-bottom: 20px;
  display: block;
}

.portfolio-detail-button {
  text-decoration: none;
  color: white;
  background-color: #555;
  border-radius: 5px;
  padding: 0.5em;
}

h2 {
  padding-top: 0;
}

@media screen and (min-width: 440px) {
}

@media screen and (min-width: 768px) {
  .portfolio-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .portfolio-list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
  }
}
